<template>
	<login-required className="reserve-best-seats-form">
		<div class="grid-container align-middle align-center">
			<div class="main-section container-white content-full">
				<div class="grid-x align-center tabs">
					<div class="cell small-6 tab-header">
						<div class="tab-title active">
							{{ translate('Schnellbuchung') }}
						</div>
					</div>
					<div class="cell small-6 tab-header" style="display:none">
						<!-- TODO -->
						<a href="#" class="tab-title deactive"><span class="icon icon-stadium show-for-medium"></span> 
							{{ translate('Saalplanbuchung') }}
						</a>
					</div>
				</div>
				<div class="grid-x grid-padding-x align-center">
					<span class="teaser-headline">
						{{ delivery_type }}
					</span>
				</div>
				<div class="grid-x grid-padding-x align-center">
					<div class="cell medium-10 large-10 xlarge-8">
						<div class="quick-booking-list">
							<ul class="information alert" v-if="errorMessages.length > 0" ref="errorMessage">
								<li v-for="msg in errorMessages" v-bind:key="msg" :style="language == 'ja' ? 'text-transform:none' : ''">
									{{ msg }}
								</li>
							</ul>

							<div v-if="!showForm" class="grid-x align-center">
								<a :href="urls.cart" class="button gradient">{{ translate('Warenkorb bearbeiten') }}</a>
							</div>

							<template v-if="showForm">
								<div class="grid-x booking-list-header show-for-medium">
									<div class="cell medium-7 large-8">
										{{ translate('Kategorie') }}
									</div>
									<div class="cell medium-2 text-right">
										{{ translate('Preis') }}
									</div>
									<div class="cell medium-3 large-2 text-right">
										{{ translate('Anzahl') }}
									</div>
								</div>
								<div class="booking-list-contents">
									<!-- booking-list-content -->
									<div v-for="cat in seats" :key="cat.category_id" class="grid-x align-middle booking-list-content">
										<div class="cell small-8 medium-7 large-8">
											<!-- span class="category-color" :style="'background-color: '+cat.color"></span -->
											<span :class="'category-color color-'+cat.category_id"></span>
											<span class="category-name">
											{{ translate(cat.name) }}
										</span>
										</div>
										<div class="cell small-4 medium-2 text-right">
										<span class="price">
											€ {{ priceFormat(cat.price) }}
										</span>
										</div>
										<div class="cell small-12 medium-3 large-2 number-container">
											<span class="number-of-tickets show-for-small-only">{{ translate('Anzahl Tickets') }}</span>
											<div class="number">
												<button v-if="cat.quantity == 0" class="minus disabled decrease-button"></button>
												<button v-else v-on:click="cat.quantity -= 1" class="minus decrease-button"></button>
												<span class="category-quantity">{{ cat.quantity }}</span>
												<button v-if="cat.quantity >= cat.count" class="plus disabled increase-button"></button>
												<button v-else v-on:click="cat.quantity += 1" class="plus increase-button"></button>
											</div>
										</div>
									</div>
									<!-- /booking-list-content -->
								</div>
								<!-- booking-list-footer -->
								<div class="grid-container booking-list-footer">
									<div class="grid-x align-center">
										<div class="cell small-12">
											<div class="grid-x align-middle flex-dir-column-reverse medium-flex-dir-row">
												<div class="cell small-4 medium-6 medium-text-left text-center">
													<a v-if="urls.sitzplanMusikvereinPDF" v-bind:href="urls.sitzplanMusikvereinPDF" target="_blank" v-html="translate('Saalplan mit Kategorien') + ' (PDF)'"></a>
												</div>
												<div class="cell small-8 medium-6 medium-text-right text-center">
													<button v-on:click="addToCart" type="button" class="button gradient">{{ translate('Plätze in den Warenkorb') }}</button>
												</div>
											</div>
										</div>
									</div>
								</div>
								<!-- /booking-list-footer -->
							</template>

						</div>
					</div>
				</div>
			</div>
		</div>
		<DisableUI :disableUI="disableUI" />
	</login-required>
</template>

<script>
	import _ from 'lodash'
	import translationMixin from '@/mixins/translation'
	import LoginRequired from '@/components/LoginRequired'
	import DisableUI from '@/components/DisableUI.vue'
	export default {
		name: 'ReserveBestSeatsForm',
		mixins: [translationMixin],
		components: { DisableUI, LoginRequired },
		props: {
			categories: Array,
			name: String,
			img: String,
			url: String,
			id: Number,
			sectors: Object,
			location: String,
			delivery_type: String,
		},
		data () {
			const seats = []
			for (let cat of this.categories) {
				cat.quantity = cat.quantity ? cat.quantity : 0
				seats.push(cat)
			}
			return {
				disableUI: this.disableUI,
				errorMessages: this.errorMessages,
				showForm: this.showForm,
				language: window.SHOP_APP.languageCode,
				seats,
			}
		},
		methods: {
			async addToCart () {
				const seats = []
				this.errorMessages = []
				for (let seat of this.seats) {
					if (seat.quantity) {
						seats.push(_.omit(seat, 'count'))
					}
				}
				if (seats.length == 0) {
					this.errorMessages.push(this.translate('Bitte eine Auswahl treffen.'))
				} else {
					this.disableUI = true
					const response = await this.$store.dispatch('reserveBestSeats', {
						id: this.id,
						name: this.name,
						img: this.img,
						url: this.url,
						sectors: this.sectors,
						location: this.location,
						delivery_type: this.delivery_type,
						seats,
					})
					if (response && response.data && response.data.errors.length > 0) {
						const seatsByCategory = this.seats.reduce((result, seat) => (result[seat.category_id] = seat, result), {})
						this.errorMessages.push(this.translate('Es ist ein Fehler aufgetreten.'))
						for (let error of response.data.errors) {
							this.errorMessages.push(this.translate(error.text, error.replacements))
							if (error.replacements && seatsByCategory[error.replacements.category_id]) {
								seatsByCategory[error.replacements.category_id].quantity = 0
							}
						}
					} else {
						window.location.assign(window.SHOP_APP.urls.cart)
					}
					this.disableUI = false
				}
				if (this.errorMessages.length > 0) {
					await this.$nextTick()
					if (this.$refs.errorMessage.scrollIntoView) {
						this.$refs.errorMessage.scrollIntoView({behavior: "smooth"})
					}
				}
			}
		},
		created () {
			this.disableUI = false
			this.errorMessages = []
			this.showForm = true
			for (let seat of _.get(this.$store, 'state.cart.tickets.seats', [])) {
				if (seat.delivery_type != this.delivery_type) {
					this.showForm = false
					this.errorMessages.push(this.translate('Es ist leider nicht möglich, Print@Home-Tickets und Tickets, die im Kartenbüro abgeholt werden müssen, gleichzeitig zu kaufen. Bitte entfernen Sie eine Ticketart aus Ihrem Warenkorb und versuchen Sie es erneut.'))
					break
				}
			}
		},
	}
</script>
