<template>
	<login-required>
		<!-- Info  -->
		<div class="grid-x grid-padding-x align-center">
			<div class="cell medium-10 xlarge-8">
				<h1 class="text-center">{{ translate('Bestellung') }} {{ order.id }}</h1>
				<div class="grid-x medium-text-left text-center order-detail">
					<div v-if="order.address" class="cell medium-4">
						<p class="subhead">
							{{ translate('Rechnungsadresse') }}
						</p>							
						<p>
							{{ order.salutation }}
							{{ order.title }}
							{{ order.first_name }}
							{{ order.last_name }}
							<br>
							{{ order.address }}
							<br>
							<template v-if="order.state">
								<state-format :country_id="order.country_id" :postcode="order.postcode" :state="order.state" :city="order.city"/>
							</template>
							<template v-else>
								{{ order.postcode }} {{ order.city }}
							</template>
							<br>
							{{ countryName(order.country_id) }}
						</p>	
					</div>
					<div v-if="order.address" class="cell medium-4">
						<p class="subhead">
							{{ translate('Lieferadresse') }}
						</p>					
						<p v-if="order.use_delivery_address">
							{{ order.delivery_salutation }}
							{{ order.delivery_title }}
							{{ order.delivery_first_name }}
							{{ order.delivery_last_name }}
							<br>
							{{ order.delivery_address }}
							<br>
							<template v-if="order.delivery_state">
								<state-format :country_id="order.delivery_country_id" :postcode="order.delivery_postcode" :state="order.delivery_state" :city="order.delivery_city"/>
							</template>
							<template v-else>
								{{ order.delivery_postcode }} {{ order.delivery_city }}
							</template>
							<br>
							{{ countryName(order.delivery_country_id) }}
						</p>
						<p v-else>
							{{ order.salutation }}
							{{ order.title }}
							{{ order.first_name }}
							{{ order.last_name }}
							<br>
							{{ order.address }}
							<br>
							<template v-if="order.state">
								<state-format :country_id="order.country_id" :postcode="order.postcode" :state="order.state" :city="order.city"/>
							</template>
							<template v-else>
								{{ order.postcode }} {{ order.city }}
							</template>
							<br>
							{{ countryName(order.country_id) }}
						</p>
					</div>
					<div v-if="order.payment_method" class="cell medium-4">
						<p class="subhead">
							{{ translate('Zahlungsart') }}
						</p>							
						<div>
							<p v-if="order.payment_method == 'creditcard'">
								{{ translate('Kreditkarte') }}:
								<span>{{ order.payment_info.type }}</span>
								<i class="cc" :class="order.payment_info.type"></i><br>
								{{ order.payment_info.number }}
							</p>
							<p v-if="order.payment_method == 'paypal'">
								{{ translate('PayPal') }}<template v-if="order.payment_info.account_holder">:
								<span>{{ order.payment_info.account_holder }}</span>
								{{ order.payment_info.email }}</template>
							</p>
							<p v-if="order.payment_method == 'sofortbanking'">
								{{ translate('Überweisung') }}:
								<span>{{ order.payment_info.account_holder }}</span>
								{{ order.payment_info.iban }}
							</p>
						</div>
					</div>
					<div v-if="trackingIDs.length > 0" class="cell medium-4">
						<p class="subhead">
							{{ translate('Sendung-Nr.') }}
						</p>							
						<div>
							<p>
								<template v-for="(tracking_id, index) in trackingIDs">
									<a :href="'https://www.post.at/sv/sendungssuche?snr=' + tracking_id" :key="tracking_id">{{ tracking_id }}</a>
									<br v-if="index != trackingIDs.length - 1" :key="tracking_id">
								</template>
							</p>
						</div>
					</div>
					<div class="cell medium-4">
						<p class="subhead">
							{{ translate('Rechnung') }}
						</p>
						<div>
							<p>
								<a v-if="order.invoice_id" :href="order.invoice">{{ translate('Rechnung herunterladen (PDF)') }}</a>
							</p>
						</div>
					</div>	
				</div>
			</div>		
		</div>		
		<div class="spacer"></div>
		<!-- Info  -->
		
		<!-- Produkte  -->
		<div class="grid-x grid-padding-x align-center">
			<div class="cell medium-10 xlarge-8">
				<div class="cart-widget">
					<h2 v-if="order.articles && order.articles.length > 0" class="text-center">{{ translate('Produkte') }}</h2>
					<div v-if="order.articles && order.articles.length > 0" class="cart-articles">
						<!-- cart-article -->
						<div class="article-row" v-for="article in order.articles" :key="article.id">
							<div class="article-image">
								<img v-if="article.image_url" :src="article.image_url"/>
							</div>
							<div class="article-text">
								<div class="article-type">{{ article.category_name }}</div>
								<div class="article-name">
									<span v-if="article.quantity > 1">{{ article.quantity }}x</span>
									{{ article.name }}
								</div>
								<div class="article-price subline primary-color">€ {{ priceFormat(article.price) }}</div>
							</div>
							<div class="arcticle-total-price">
								<div class="article-price subline primary-color">€ {{ priceFormat(article.price * article.quantity) }}</div>
							</div>
						</div>
						<!-- /cart-article -->

						<!-- cart-article -->
						<div class="article-row" v-for="seat in order.seats" :key="seat.id">
							<div class="article-image">
								<img v-if="seat.image_url" :src="seat.image_url"/>
							</div>
							<div class="article-text">
								<div class="article-type">{{ translate('Kategorie') }} {{ seat.category_id }}</div>
								<div href="#" class="article-name">{{ seat.event_name }}</div>
								<div class="article-price subline primary-color">{{ dateFormat(seat.event_date) }}</div>
							</div>
							<div class="arcticle-total-price">
								<div class="article-price subline primary-color">€ {{ priceFormat(seat.price) }}</div>
							</div>
						</div>
						<!-- /cart-article -->
					</div>					
					<div class="cart-widget-footer">
						<div class="wrapper">
							<div v-if="order.price_sum" class="summary subline">
								<span class="text-light">{{ translate('Bestellwert') }}</span>
								<span>
									<span class="net-sum primary-color">€ {{ priceFormat(order.price_sum) }}</span>
								</span>
							</div>
							<div v-if="order.delivery_cost" class="summary subline">
								<span class="text-light">{{ translate('Versandkosten') }}</span>
								<span>
									<span class="net-sum primary-color">€ {{ priceFormat(order.delivery_cost) }}</span>
								</span>
							</div>
							<div class="summary-total subline">
								<span class="text-light">{{ translate('Gesamt') }}</span>
								<span>
									<span class="net-sum">€ {{ priceFormat(order.total_cost) }}</span>
									<span class="tax-info">{{ translate('Inkl. Ust.') }}</span>
								</span>
							</div>																					

						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- Produkte  -->
	</login-required>
</template>

<script>
    import translationMixin from '@/mixins/translation'
	import LoginRequired from '@/components/LoginRequired'
	import StateFormat from '@/components/StateFormat'
    export default {
        name: 'OrderDetail',
        mixins: [translationMixin],
		components: { LoginRequired, StateFormat },
        props: {
            limit: null,
        },
        data () {
            return {
                order: {},
                trackingIDs: [],
            }
        },
        methods: {
            async getOrder (orderId) {
                const api = await this.$store.dispatch('api')
                const response = await api.get(`${window.SHOP_APP.baseURL}/order/${orderId}`)
                this.order = response.data
                this.trackingIDs = this.order.articles.filter(a => a.gramola_tracking_id).map(a => a.gramola_tracking_id)
            },
            countryName (country_id) {
                for (let country of this.getTranslations().country_list) {
                    if (country.id === country_id) {
                        return country.name
                    }
                }
            },
        },
        async created () {
            const orderId = window.location.hash ? window.location.hash.substr(1) : undefined
            if (this.$store.getters.isLoggedIn() && ! isNaN(orderId)) {
                await this.getOrder(orderId)
				if (window.$) {
					const title = `${this.translate('Bestellung')} ${this.order.id}`
					window.$('ul.breadcrumbs li.current').text(title)
					window.$('title').text(window.$('title').text().replace('  ', title))
				}
            }
        },
    }
</script>
