<template>
	<form v-on:submit.prevent="callAPI" v-bind:class="{ error: error }" class="custom-form send-reset-password-mail-form small-form">
		<div class="form-row">
			<label for="id_password">{{ translate('Neues Passwort') }}</label>
			<input id="id_password" v-model="formData.password" type="password">
		</div>
		<div v-bind:class="{ erroneous: errors.password_repeat }" class="form-row">
			<p v-if="errors.password_repeat" class="error">{{ errors.password_repeat }}</p>
			<label for="id_password_repeat">{{ translate('Passwort wiederholen') }}</label>
			<input id="id_password_repeat" v-model="password_repeat" type="password">
		</div>
		<div class="form-row button-row">
			<p v-if="error" class="error">{{ translate('Die Anmeldung ist fehlgeschlagen') }}</p>
			<button type="submit" class="button gradient small-only-expanded">{{ translate('Passwort speichern') }}</button>
		</div>
	</form>
</template>

<script>
    import FormForShopApiMixin from '@/mixins/formForShopApi'
    export default {
        name: 'SendResetPasswordMailForm',
        mixins: [FormForShopApiMixin],
        data () {
            return {
                password_repeat: '',
                formData: {
                    token: window.location.hash.substr(1),
				},
                formUrl: `password`,
				formOptions: { method: 'put' },
				error: false
            }
        },
        methods: {
            async formSuccess (data) {
                window.location.replace(window.SHOP_APP.urls.reset_password_completed)
                return data
            },
            async formError (error) {
                this.error = true
				return error
            },
            async validate (data) {
                if (data.password != this.password_repeat) {
                    this.errors['password_repeat'] = this.translate('Die Passwörter stimmen nicht überein')
                }
                return data
            },
        },
    }
</script>
