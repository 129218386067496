<template>
	<login-required className="grid-container section-container">
		<div class="grid-x grid-margin-x grid-margin-y">
			<div class="cell large-5 xlarge-4 large-order-2">
				<div class="main-section container-white cart-article-box">
					<div class="grid-x grid-padding-x align-center">
						<div class="cell medium-11 large-10">
							<span class="cart-headline preline text-center">{{ translate('Meine Artikel') }}</span>
							<div class="cart-articles">
								<div v-for="seat in seats" v-bind:key="seat.event_id + '-' + seat.id" class="article-row">
									<div class="article-name subline text-light">
										{{ seat.event_name }}
										<span :style="'color: '+seat.color">⬤</span>
										{{ seat.name }}
									</div>
									<div class="article-image">
										<img :src="seat.event_img" />
									</div>
								</div>
								<div v-for="article in articles" v-bind:key="article.id" class="article-row">
									<div class="article-name subline text-light">
										<span v-if="article.quantity > 1">{{ article.quantity }}x</span>
										{{ article.name }}
									</div>
									<div class="article-image">
										<img :src="article.img" />
									</div>
								</div>
							</div>
							<div class="price-summary subline">
								<div class="price-row">
									<span class="text-light">{{ translate('Bestellwert') }} </span>
									<span class="primary-color">{{ priceFormat(orderInfo.price_sum) }}</span>
								</div>
								<div class="price-row">
									<span class="text-light">{{ translate('Versandkosten') }} </span>
									<span class="primary-color">{{ priceFormat(orderInfo.delivery_cost) }}</span>
								</div>
							</div>
							<div class="price-total price-row subline">
								<span class="text-light">{{ translate('Gesamt') }} </span>
								<span>
									<span class="total-value">{{ priceFormat(orderInfo.total_cost) }}</span>
									<span class="tax-info">{{ translate('Inkl. Ust.') }}</span>
								</span>
							</div>
							<div class="text-right">
								<a :href="urls.cart" class="subline">{{ translate('Warenkorb bearbeiten') }}</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="cell large-7 xlarge-8">
				<div class="main-section container-white content content-full">
					<div class="grid-x grid-padding-x align-center">
						<div class="cell medium-10 large-8 text-center">
							<h2>{{ translate('Rechnungsadresse') }}</h2>
							<p>
								{{ orderInfo.salutation }}
								{{ orderInfo.title }}
								{{ orderInfo.first_name }}
								{{ orderInfo.last_name }}
								<br>
								{{ orderInfo.address }}
								<br>
								<template v-if="orderInfo.state">
									<state-format :country_id="orderInfo.country_id" :postcode="orderInfo.postcode" :state="orderInfo.state" :city="orderInfo.city"/>
								</template>
								<template v-else>
									{{ orderInfo.postcode }} {{ orderInfo.city }}
								</template>
								<br>
								{{ countryName(orderInfo.country_id) }}
							</p>
							<p class="subline">
								<a :href="urls.self_care">{{ translate('Rechnungsadresse ändern') }}</a>
							</p>

							<template v-if="articles.length > 0">

								<hr>
								<h2>{{ translate('Lieferadresse') }}</h2>
								<p v-if="orderInfo.use_delivery_address" :key="deliveryAddressFormVersion">
									{{ orderInfo.delivery_salutation }}
									{{ orderInfo.delivery_title }}
									{{ orderInfo.delivery_first_name }}
									{{ orderInfo.delivery_last_name }}
									<br>
									{{ orderInfo.delivery_address }}
									<br>
									<template v-if="orderInfo.delivery_state">
										<state-format :country_id="orderInfo.delivery_country_id" :postcode="orderInfo.delivery_postcode" :state="orderInfo.delivery_state" :city="orderInfo.delivery_city"/>
									</template>
									<template v-else>
										{{ orderInfo.delivery_postcode }} {{ orderInfo.delivery_city }}
									</template>
									<br>
									{{ countryName(orderInfo.delivery_country_id) }}
								</p>
								<p v-else>
									{{ translate('Lieferadresse entspricht der Rechnungsadresse') }}
								</p>
								<button v-on:click.prevent="openDeliveryModal" class="button hollow secondary small-only-expanded">{{ translate('Lieferadresse ändern') }}</button>
								<p v-if="orderInfo.use_delivery_address" class="subline">
									<a v-on:click.prevent="unsetUseDeliveryAddress" class="dont-use-delivery-address">{{ translate('Rechnungsadresse verwenden') }}</a>
								</p>

							</template>

							<hr>
							<h2>{{ translate('Zahlungsart und Bestellung') }}</h2>

							<div class="form-row payment-row">
								<input id="id_payment_cc" v-model="formData.payment_method" value="creditcard" type="radio">
								<label for="id_payment_cc">{{ translate('Kreditkarte') }}</label>
								<div class="payment-images">
									<span class="icon icon-payment-mastercard">Mastercard</span>
									<span class="icon icon-payment-visa">VISA</span>
									<span class="icon icon-payment-ae">American Express</span>
									<span class="icon icon-payment-diners">Diners Club</span>
									<span class="icon icon-payment-jcb">JCB</span>									
								</div>
							</div>
							<div class="form-row payment-row">
								<input id="id_payment_paypal" v-model="formData.payment_method" value="paypal" type="radio">
								<label for="id_payment_paypal">{{ translate('PayPal') }}</label>
								<div class="payment-images">
									<span class="icon icon-payment-paypal">PayPal</span>
								</div>
							</div>
							<div v-if="TODO" class="form-row payment-row">
								<input id="id_payment_sofort" v-model="formData.payment_method" value="sofort" type="radio">
								<label for="id_payment_sofort">{{ translate('Sofortüberweisung') }}</label>
								<div class="payment-images">
									<span class="icon icon-payment-sofort">Sofort</span>
								</div>
							</div>

							<p>{{ translate('Mit dem Klick auf "Zahlungspflichtig bestellen" akzeptieren Sie die Allgemeinen Geschäftsbedingungen der Wiener Philharmoniker.') }}</p>

							<div class="button-row">
								<button v-on:click.prevent.once="gotoPayment" type="button" class="button button-go-to-payment">{{ translate('Zahlungspflichtig bestellen') }}</button>
							</div>

							<div id="checkout-delivery-address-form" style="display:none">
								<edit-delivery-address-form
										:initial="deliveryFormData"
										:formUrl="`order/${orderInfo.id}`"
										:successCallback="deliveryModalSuccessCallback"
										:key="deliveryAddressFormVersion"
										ref="deliveryAddressForm"
								/>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>
	</login-required>

</template>

<script>
	import _ from 'lodash'
	import EditDeliveryAddressForm from '@/components/EditDeliveryAddressForm.vue'
	import LoginRequired from '@/components/LoginRequired'
	import translationMixin from '@/mixins/translation'
	import StateFormat from '@/components/StateFormat'
	export default {
		name: 'CheckoutForm',
		components: { EditDeliveryAddressForm, LoginRequired, StateFormat },
		mixins: [translationMixin],
		data () {
			return {
				formData: {
					use_delivery_address: false,
					payment_method: null,
				},
				error: false,
				loaded: false,
				orderInfo: {},
				deliveryAddressFormVersion: 0, // TODO: remove
				cancelOnUnload: true,
			}
		},
		computed: {
			deliveryFormData () {
				const formData = _.pickBy(this.orderInfo, function (v, k) { return _.startsWith(k, 'delivery_')})
				formData.use_delivery_address = true
				return formData
			},
			articles () {
				return this.$store.state.cart.articles
			},
			seats () {
				return this.$store.state.cart.tickets.seats || []
			},
		},
		methods: {
			async createOrder () {
				this.loaded = false
				const api = await this.$store.dispatch('api')
				const data = {
					articles: this.$store.state.cart.articles,
					use_delivery_address: false,
					jetticket_order_id: this.$store.state.cart.tickets.order_id,
					jetticket_session_id: this.$store.state.cart.tickets.session_id,
					language: window.SHOP_APP.languageCode,
				}
				try {
					const response = await api.post(`${window.SHOP_APP.baseURL}/order`, data)
					this.orderInfo = response.data
					this.loaded = true
					this.deliveryAddressFormVersion = 1
				} catch (error) {
					const messages = []
					const articleMap = this.$store.state.cart.articles.map(a => { return { name: a.name, id: a.id }})
					try {
						for (const e of error.response.data.detail) {
							if (e.msg == 'out of stock') {
								const article = articleMap[e.loc[e.loc.length - 2]]
								messages.push(this.translate('Der Artikel <strong>{name}</strong> ist nicht mehr verfügbar und wurde aus Ihrem Warenkorb entfernt.', article))
								this.$store.commit('deleteArticle', article.id)
							}
						}
					} catch {
						//empty
					}
					messages.push(`${this.translate('Bitte überprüfen Sie Ihre Bestellung')}<br><br><a href="${this.urls.cart}" class="button hollow secondary expanded">${this.translate('Warenkorb anzeigen')}</a>`)
					this.showAPIError(messages.join('<br>'))
				}
			},
			countryName (country_id) {
				for (let country of this.getTranslations().country_list) {
					if (country.id === country_id) {
						return country.name
					}
				}
			},
			async unsetUseDeliveryAddress () {
				this.loaded = false
				const api = await this.$store.dispatch('api')
				const response = await api.patch(`${window.SHOP_APP.baseURL}/order/${this.orderInfo.id}`, { use_delivery_address: false })
				this.orderInfo = response.data
				this.loaded = true
			},
			openDeliveryModal () {
				// eslint-disable-next-line no-undef
				if (window.$) {
					// eslint-disable-next-line no-undef
					$.fancybox.open({
						src: "#checkout-delivery-address-form",
						baseClass: "content-overlay",
					})
				}
			},
			async deliveryModalSuccessCallback (data) {
				this.orderInfo = data
				if (window.$) {
					const compo = this
					setTimeout( function () {
						// eslint-disable-next-line no-undef
						$.fancybox.close()
						compo.deliveryAddressFormVersion += 1
					}, 2088)
				}
			},
			async gotoPayment () {
				this.loaded = false
				this.cancelOnUnload = false
				const api = await this.$store.dispatch('api')
				const response = await api.post(
					`${window.SHOP_APP.baseURL}/payment/redirect`,
					{ order_id: this.orderInfo.id, payment_method: this.formData.payment_method, locale: window.SHOP_APP.languageCode })
				window.location.replace(response.data.url)
			},
			async cancelOrder () {
				this.loaded = false
				const api = await this.$store.dispatch('api')
				const response = await api.get(`${window.SHOP_APP.baseURL}/order/${this.orderInfo.id}/cancel`)
				this.orderInfo = response.data
				this.loaded = true
			},
		},
		async created () {
			if (this.$store.getters.isLoggedIn() == false) {
				return
			}
			await this.createOrder()
			window.addEventListener('beforeunload', async () => {
				if (this.cancelOnUnload) {
					await this.cancelOrder()
				}
			}, false)
		},
	}
</script>
