<template>
	<li class="nav-li dropdown-container user-menu-main" id="user-widget">
		<a href="javascript:;" class="nav-a open-dropdown icon user-icon" v-bind:class="{ active: isLoggedIn() }"></a>
        <span class="user-menu-title hide-for-large">{{ translate('Mein Bereich') }}</span>
		<ul v-if="isLoggedIn()" :key="isLoggedIn()">
			<li class="subline primary-color">{{ translate('Willkommen') }}<br>{{ $store.state.user.salutation }}</li>
			<li class="seperator"></li>
			<li class="subline primary-color"><a :href="urls.self_care">{{ translate('Mein Bereich') }}</a></li>
			<li class="subline primary-color"><a :href="urls.order_list">{{ translate('Meine Bestellungen') }}</a></li>
			<li><button v-on:click="logout" type="button" class="button hollow secondary button-logout icon-logout">{{ translate('Abmelden') }}</button></li>
		</ul>
		<ul v-else>
			<li class="subline primary-color"><a :href="urls.login">{{ translate('Anmelden') }}</a></li>
			<li class="subline primary-color"><a :href="urls.registration_form">{{ translate('Registrieren') }}</a></li>
			<li class="seperator"></li>
			<li class="subline primary-color"><a :href="urls.self_care">{{ translate('Mein Bereich') }}</a></li>
			<li class="subline primary-color"><a :href="urls.order_list">{{ translate('Meine Bestellungen') }}</a></li>
		</ul>
	</li>
</template>

<script>
	import { mapGetters } from 'vuex'
    import translationMixin from '@/mixins/translation'
    export default {
        name: 'UserWidget',
        mixins: [translationMixin],
		methods: {
            async logout () {
                await this.$store.dispatch('logout')
                this.setBodyLoggedInClass()
			},
			setBodyLoggedInClass () {
				document.body.className = this.isLoggedIn() ? document.body.className + ' logged-in' : document.body.className = document.body.className.replace(' logged-in', '')
			},
        },
        computed: {
			...mapGetters(['isLoggedIn']),
        },
        mounted () {
            if (window.$) {
				window.$(".open-dropdown.user-icon").dropdownOpen();
            }
        },
		created () {
			this.setBodyLoggedInClass()
		},
    }
</script>
