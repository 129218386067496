<template>
    <div class="optin-form">
    </div>
</template>

<script>
    import axios from 'axios'
    export default {
        name: 'OptinForm',
        methods: {
            async callAPI() {
                const token = window.location.hash.substr(1)
                if (token.length == 0) {
                    return this.formError()
                }
                await axios.put(`${window.SHOP_APP.baseURL}/optin`, { token })
                    .then( (response) => {
                        if (Object.prototype.hasOwnProperty.call(response, 'data') && response.data === true) {
                            this.formSuccess()
                        } else {
                            this.formError({ response })
                        }
                    })
                    .catch( (error) => {
                        this.formError(error)
                    })
            },
            formSuccess () {
                window.location.replace(window.SHOP_APP.urls.optin_completed)
            },
            formError (error) {
				if (error.response && error.response.data && error.response.data.detail) {
					if (error.response.data.detail[0].msg === 'expired') {
						return window.location.replace(window.SHOP_APP.urls.optin_expired)
					}
				}
                window.location.replace(window.SHOP_APP.urls.optin_error)
            }
        },
        async mounted () {
            await this.callAPI()
        },
    }
</script>
