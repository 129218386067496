<template><meta></template>
<script>
    export default {
        name: 'DisableUI',
        render: () => null,
        props: {
            disableUI: Boolean,
        },
        data () {
            return {
                fancyboxInstance: null,
            }
        },
        methods: {
            setDisabled (disabled) {
                if (window.$) {
                    if (disabled) {
                        this.fancyboxInstance = window.$.fancybox.open(window.$('#disableUI'), { modal: true })
                        this.fancyboxInstance.showLoading()
                    } else {
                        if (this.fancyboxInstance != null) {
                            this.fancyboxInstance.close()
                        } else {
                            window.$.fancybox.close()
                        }
                        this.fancyboxInstance = null
                    }
                }
            },
        },
        watch: {
            disableUI: function (newValue, oldValue) {
                if (newValue != oldValue) {
                    this.setDisabled(newValue)
                }
            }
        }
    }
</script>
