import axios from 'axios'

const state = {
    id: null,
    exp: null,
    expRefresh: null,
    salutation: null,
    nextURL: null,
}
const mutations = {
    setUserData (state, userData) {
        state.id = userData.id
        state.salutation = userData.salutation
        state.exp = userData.exp ? (Date.now() / 1000) + 60 * userData.exp : null
        state.expRefresh = userData.refresh_exp ? (Date.now() / 1000) + 60 * userData.refresh_exp : null
    },
    setNextURL (state, url) {
        state.nextURL = url
    },
}
export const actions = {
    async login ({ commit }, credentials) {
        const data = new FormData();
        data.append('username', credentials.username)
        data.append('password', credentials.password)
        const response = await axios.post(`${window.SHOP_APP.baseURL}/token`, data)
        commit('setUserData', response.data)
    },
    async logout ({ commit, dispatch }) {
        try {
            await dispatch('cancelJTOrder')
        } catch {
            // empty
        }
        await axios.delete(`${window.SHOP_APP.baseURL}/token`)
        commit('setUserData', {})
        commit('clearCart')
    },
    async refreshToken ({ commit, state }) {
        try {
            const response = await axios.put(`${window.SHOP_APP.baseURL}/token`, { user_id: state.id })
            commit('setUserData', response.data)
        } catch (e) {
            commit('setUserData', {})
        }
    },
    async testToken (context) {
        if (context.getters.needsToRefreshToken()) {
            context.dispatch('refreshToken')
        }
    },
    setNextURL ({ commit }, url) {
        commit('setNextURL', url)
    },
    popNextURL ({ commit, state }) {
        const url = state.nextURL
        commit('setNextURL', null)
        return url
    },
    api (context) {
        const headers = {}
        /*
        if (context.state.accessToken) {
            headers['Authorization'] = 'Bearer ' + context.state.accessToken
        }
        */
        const axiosInstance = axios.create({ headers })
        axiosInstance.interceptors.response.use(
            function (response) {
                return response
            },
            async (error) => {
                const request = error.config
                if (401 === error.response.status && !request._isRetry) {
                    try {
                        await context.dispatch('refreshToken')
                        request._isRetry = true
                        return axiosInstance(request)
                    } catch {
                        // empty
                    }
                }
                if (error.response && [402, 403].includes(error.response.status)) {
                    context.commit('setUserData', {})
                }
                return Promise.reject(error)
            }
        )
        return axiosInstance
    }
}
export const getters = {
    isLoggedIn: state => () => {
        return state.exp > Date.now() / 1000
    },
    needsToRefreshToken: state => () => {
        const now = Date.now() / 1000
        return state.expRefresh > now && now > state.exp - 60
    },
    userId: state => {
        return state.id
    }
}

export default {
    state,
    mutations,
    actions,
    getters,
}