import _ from 'lodash'
import axios from 'axios'
import translationMixin from '@/mixins/translation'

export default {
    data () {
        return {
            formData: {},
            errors: {},
            formOptions: {},
        }
    },
    methods: Object.assign(
        translationMixin.methods,
        {
            async validate (data) {
                return data
            },
            async callAPI (options) {
                this.errors = {}
                let data = _.clone(this.formData, true)
                data = await this.validate(data)
                if (Object.keys(this.errors).length > 0) {
                    return
                }
                const settings = {...{ axios, method: 'post' }, ...this.formOptions, ...options}
                try {
                    const response = await settings.axios[settings.method](`${window.SHOP_APP.baseURL}/${this.formUrl}`, data)
                    await this.formSuccess(response.data)
                } catch (error) {
                    this.errors = {}
                    if (error.response && error.response.data && error.response.data.detail && error.response.data.detail instanceof Array) {
                        for (const e of error.response.data.detail) {
                            let msg = this.translate(e.msg, e.ctx)
                            if (msg == e.msg) {
                                msg = this.translate(e.type, e.ctx)
                            }
                            if (msg == e.type) {
                                msg = e.msg
                            }
                            this.errors[e.loc.pop()] = msg
                        }
                    }
                    this.formError(error)
                }
            },
            async formError (error) {
                return error
            },
        }
    )
}
