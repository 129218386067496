<template>
	<form v-on:submit.prevent="callAPI" v-bind:class="{ error: error }" class="custom-form login-form small-form">
		<div class="form-row">
			<label for="id_username">{{ translate('Ihre E-Mail Adresse') }}</label>
			<input id="id_username" v-model="formData.username" type="text">
		</div>
		<div class="form-row">
			<label for="id_password">{{ translate('Ihr Passwort') }}</label>
			<input id="id_password" v-model="formData.password" type="password">
		</div>
		<div class="form-row">
			<a :href="urls.send_reset_password_mail">{{ translate('Ich habe mein Passwort vergessen') }}</a>
            <p v-if="error" class="error">{{ translate('Die Anmeldung ist fehlgeschlagen') }}</p>
        </div>
        <div class="form-row button-row">
			<button type="submit" class="button gradient small-only-expanded">{{ translate('Anmelden') }}</button>
		</div>
    </form>
</template>

<script>
	import translationMixin from '@/mixins/translation'
    export default {
        name: 'LoginForm',
		mixins: [translationMixin],
        data () {
            return {
                formData: {},
                error: false
            }
        },
        methods: {
            async callAPI() {
                try {
                    await this.$store.dispatch('login', this.formData)
                    this.$store.commit('clearJTSession')
					await this.redirect()
				} catch (e) {
                    this.error = true
                }
            },
            async redirect () {
                let url = await this.$store.dispatch('popNextURL')
				if ( ! url ) {
                    // eslint-disable-next-line no-undef
                    url = SHOP_APP.urls.self_care
                    if (this.$store.getters.cartSum > 0) {
                        // eslint-disable-next-line no-undef
                        url = SHOP_APP.urls.cart
                    }
				}
                // eslint-disable-next-line no-undef
                window.location.replace(url)
            },
        },
    }
</script>
