import _ from 'lodash'
import translationMixin from '@/mixins/translation'

const trans = translationMixin.methods

const state = {
    articles: [],
    tickets: {},
}
const mutations = {
    addArticle (state, article) {
        for (let a of state.articles) {
            if (a.id === article.id) {
                a.quantity += article.quantity
                return
            }
        }
        state.articles.push(article)
    },
    increaseArticleQuantity (state, articleId) {
        for (let a of state.articles) {
            if (a.id === articleId) {
                a.quantity += 1
                return
            }
        }
    },
    decreaseArticleQuantity (state, articleId) {
        for (let a of state.articles) {
            if (a.id === articleId && a.quantity > 1) {
                a.quantity -= 1
                return
            }
        }
    },
    deleteArticle (state, articleId) {
        state.articles = state.articles.filter(article => article.id !== articleId)
    },
    clearCart (state) {
        state.articles = []
        state.tickets = {}
    },
    setJTOrderInfo (state, orderInfo) {
        state.tickets.session_id = orderInfo.session_id
        state.tickets.order_id = orderInfo.order_id
        state.tickets.expires = orderInfo.expires ? (Date.now() / 1000) + orderInfo.expires : state.tickets.expires
    },
    addSeats (state, seats) {
        if (state.tickets.seats == undefined) {   // TODO: default
            state.tickets.seats = []
        }
        state.tickets.seats.push(...seats)
    },
    removeSeats (state, seats) {
        const removeFilter = seats.map(seat => `${seat.event_id}-${seat.id}`)
        state.tickets.seats = state.tickets.seats.filter(seat => ! _.includes(removeFilter, `${seat.event_id}-${seat.id}`))
    },
    clearJTSession (state) {
        state.tickets = {}
    }
}
const getters = {
    cartSum: state => {
        let sum = state.articles.reduce(
            (sum, article) => sum + article.quantity * article.price, 0)
        if (state.tickets.seats) {
            sum += state.tickets.seats.reduce((sum, seat) => sum + seat.price, 0)
        }
        return parseFloat(sum.toFixed(2))
    },
    expiredJTSession: state => () => {
        return state.tickets.expires && state.tickets.expires <= Date.now() / 1000
    },
}
export const actions = {
    async reserveBestSeats ({ commit, dispatch, state }, reserveSeats) {
        const data = {
            event_id: reserveSeats.id,
            seats: [],
            session_id: state.tickets.session_id,
            order_id: state.tickets.order_id,
        }
        const categories = {}
        for (let seat of reserveSeats.seats) {
            data.seats.push({ category_id: seat.category_id, count: seat.quantity })
            categories[seat.category_id] = {
                ..._.omit(seat, 'quantity'),
                event_name: reserveSeats.name, event_img: reserveSeats.img, event_url: reserveSeats.url}
        }
        let response
        const api = await dispatch('api', null, { root: true })
        try {
            response = await api.post(`${window.SHOP_APP.baseURL}/tickets/best-seats`, data)
        } catch (error) {
            response = error.response
        }
        const orderInfo = {...response.data}
        if (orderInfo.seats && orderInfo.seats.length > 0) {
            commit('setJTOrderInfo', _.omit(orderInfo, 'seats'))
            commit('addSeats', orderInfo.seats.map((seat) => {
                const reihe_platz = trans.translate('Reihe {row}, Platz {seat}', seat),
                      sectorObj = _.get(reserveSeats, 'sectors.' + seat.sector_id, {})
                let seat_name = reihe_platz
                if (sectorObj.name_1 && sectorObj.name_2) {
                    seat_name = trans.translate(sectorObj.name_1) + ' ' + trans.translate(sectorObj.name_2) + ', ' + reihe_platz
                } else if (sectorObj.name_1) {
                    seat_name = trans.translate(sectorObj.name_1) + ', ' + trans.translate('Platz {seat}', seat)
                }
                return {
                    ...categories[seat.category_id],
                    ..._.omit(seat, 'sector_id'),
                    seat_name,
                    location: reserveSeats.location,
                    delivery_type: reserveSeats.delivery_type,
                }
            }))
        }
        return response
    },
    async removeSeats ({ commit, dispatch, state }, removeSeats) {
        const data = {
            seats: [],
            session_id: state.tickets.session_id,
            order_id: state.tickets.order_id,
        }
        for (let seat of removeSeats.seats) {
            data.seats.push({ event_id: seat.event_id, seat_id: seat.id })
        }
        const api = await dispatch('api', null, { root: true })
        let response = await api.delete(`${window.SHOP_APP.baseURL}/tickets/seat`, {data})
        commit('removeSeats', response.data.deleted.map(i => ({id: i.seat_id, event_id: i.event_id})))
        if (response.data.errors && response.data.errors.length > 0) {
            trans.showAPIError("Karten konnten nicht aus dem Warenkorb entfernt werden")
        }
    },
    async testJTSession ({ commit, getters, dispatch }) {
        if (window.SHOP_APP.isCheckout) {
            return
        }
        if (getters.expiredJTSession() == true) {
            try {
                await dispatch('cancelJTOrder')
            } catch {
                // empty
            }
            commit('clearJTSession')
            // eslint-disable-next-line no-undef
            if (window.$) {
                // eslint-disable-next-line no-undef
                window.$.fancybox.open($('#ticket-reservation-expired'))
            }
        }
    }
}

export default {
    state,
    mutations,
    getters,
    actions,
    trans,
}