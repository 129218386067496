<template>
	<div v-if="$store.getters.isLoggedIn()" v-bind:class="className">
		<slot></slot>
	</div>
</template>

<script>
	export default {
		props: {
			className: String,
			nextURL: String,
		},
		created () {
			if (this.$store.getters.isLoggedIn() == false) {
				this.$store.dispatch('setNextURL', this.nextURL || window.location.href.split(window.location.host).pop())
				window.location.replace(window.SHOP_APP.urls.login)
			}
		},
	}
</script>
