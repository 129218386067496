<script>
	export default {
		props: {
			delay: null,
			wait: null,
		},
		name: 'Interval',
		render: () => null,
		mounted () {
			if (this.wait === false) {
				this.$emit('tick')
			}
			this.interval = window.setInterval(() => this.$emit('tick'), this.delay)
		},
		beforeDestroy () {
			window.clearInterval(this.interval)
		}
	}
</script>
