<template>
    <div class="cart-widget">
        <h1 class="text-center">{{ translate('Warenkorb') }}</h1>
        <div v-if="seats.length > 0" class="information reserved">
			<ticket-reservation-countdown></ticket-reservation-countdown>
			<span>{{ translate('Karten reserviert') }}</span>
		</div>
        <div class="cart-articles">
			<div v-for="seat in seats" v-bind:key="seat.event_id + '-' + seat.id" class="article-row">
				<div class="article-image">
					<img :src="seat.event_img" />
				</div>
				<div class="article-text">
					<div class="preline">
						<span :style="'color: '+seat.color">⬤</span>
						{{ seat.name }}
					</div>
					<a class="article-name" :href="seat.event_url">{{ seat.event_name }}</a>
					<div class="article-description">
						{{ seat.location }}<br>
						{{ seat.seat_name }}<br>
						{{ seat.delivery_type }}
					</div>
					<div class="article-price subline primary-color">€ {{ priceFormat(seat.price) }}</div>
				</div>
				<button v-on:click="removeSeats({seats:[seat]})" class="delete-button"><span class="hide-for-small-only">{{ translate('Löschen') }}</span></button>
			</div>
            <div v-for="article in articles" v-bind:key="article.id" class="article-row">
                <div class="article-image">
                    <img :src="article.img" />
                </div>
                <div class="article-text">
                    <div class="preline" v-if="article.category">{{ article.category }}</div>
                    <a class="article-name" :href="article.url">{{ article.name }}</a>
                    <div class="article-price subline primary-color">€ {{ priceFormat(article.price) }}</div>
                </div>
                <div class="amount-changer">
                    <button v-if="article.quantity == 1" class="decrease-button disabled">-</button>
                    <button v-else v-on:click="decreaseArticleQuantity(article.id)" class="decrease-button">-</button>

                    <span class="number">{{ article.quantity }}</span>

                    <button v-if="article.quantity >= article.maxQuantity" class="increase-button disabled">+</button>
                    <button v-else v-on:click="increaseArticleQuantity(article.id)" class="increase-button">+</button>
                </div>
                <button v-on:click="deleteArticle(article.id)" class="delete-button"><span class="hide-for-small-only">{{ translate('Löschen') }}</span></button>
            </div>
        </div>
        <div class="cart-widget-footer">
            <div class="wrapper">
                <div class="summary subline">
                    <span class="text-light">
                        {{ translate('Bestellwert') }}
                    </span>
                    <span>
                        <span class="net-sum primary-color">{{ netSum }} €</span>
                        <span class="tax-info">{{ translate('Inkl. Ust.') }}</span>
                    </span>
                </div>
                <div class="button-row">
                    <a :href="urls.checkout" class="button gradient expanded">{{ translate('Zur Kasse') }}</a>
                    <a :href="urls.continue_shopping" class="button secondary hollow expanded">{{ translate('Weiter einkaufen') }}</a>
                </div>
            </div>
        </div>
		<DisableUI :disableUI="isDisabledUI" />
    </div>
</template>

<script>
    import translationMixin from '@/mixins/translation'
    import DisableUI from '@/components/DisableUI.vue'
	import TicketReservationCountdown from '@/components/TicketReservationCountdown'
    export default {
        name: 'CartEditor',
        mixins: [translationMixin],
        components: { DisableUI, TicketReservationCountdown },
        data () {
            return {
                disableUI: false,
            }
        },
        computed: {
            articles () {
                return this.$store.state.cart.articles
            },
            seats () {
                return this.$store.state.cart.tickets.seats || []
            },
            netSum () {
                return this.priceFormat(this.$store.getters.cartSum)
            },
            isDisabledUI () {
                return this.disableUI
            },
        },
        methods: {
            increaseArticleQuantity (articleId) {
                this.$store.commit('increaseArticleQuantity', articleId)
            },
            decreaseArticleQuantity (articleId) {
                this.$store.commit('decreaseArticleQuantity', articleId)
            },
            deleteArticle (articleId) {
                this.$store.commit('deleteArticle', articleId)
            },
            async removeSeats (seats) {
                this.disableUI = true
				await this.$store.dispatch('removeSeats', seats)
                this.disableUI = false
            },
            async cancelOrder (orderId) {
                const api = await this.$store.dispatch('api')
                const response = await api.get(`${window.SHOP_APP.baseURL}/order/${orderId}/cancel`)
                this.orderInfo = response.data
            },
        },
        async created () {
            const orderId = window.location.hash ? window.location.hash.substr(1) : undefined
            if (this.$store.getters.isLoggedIn() && ! isNaN(orderId)) {
                await this.cancelOrder(orderId)
            }
        },
    }
</script>
