<template>
	<form v-on:submit.prevent="callAPI" class="custom-form registration-form">

		<p v-if="errors.username" class="error">{{ errors.username }}</p>
		<div v-bind:class="{ erroneous: errors.username }" class="form-row">
			<label for="id_username">{{ translate('Ihre E-Mail Adresse') }}</label>
			<input id="id_username" v-model="formData.username" type="text">
		</div>

		<p v-if="errors.username_repeat" class="error">{{ errors.username_repeat }}</p>
		<div v-bind:class="{ erroneous: errors.username_repeat }" class="form-row">
			<label for="id_username_repeat">{{ translate('E-Mail Adresse wiederholen') }}</label>
			<input id="id_username_repeat" v-model="formData.username_repeat" type="text">
		</div>

		<p v-if="errors.password" class="error">{{ errors.password }}</p>
		<div v-bind:class="{ erroneous: errors.password }" class="form-row">
			<label for="id_password">{{ translate('Passwort') }}</label>
			<input id="id_password" v-model="formData.password" type="password">
		</div>

		<p v-if="errors.password_repeat" class="error">{{ errors.password_repeat }}</p>
		<div v-bind:class="{ erroneous: errors.password_repeat }" class="form-row">
			<label for="id_password_repeat">{{ translate('Passwort wiederholen') }}</label>
			<input id="id_password_repeat" v-model="formData.password_repeat" type="password">
		</div>

		<hr>
		<legend>{{ translate('Persönliche Daten') }}</legend>

		<template v-if="formData.language != 'ja'">
			<p v-if="errors.salutation" class="error">{{ errors.salutation }}</p>
			<div v-bind:class="{ erroneous: errors.salutation }" class="form-row">
				<label for="id_salutation">{{ translate('Anrede') }}</label>
				<select id="id_salutation" v-model="formData.salutation">
					<option disabled selected>{{ translate('Bitte auswählen') }}</option>
					<option v-for="salutation of getTranslations().salutation_list" v-bind:value="salutation" v-bind:key="salutation">{{ salutation }}</option>
				</select>
			</div>

		</template>

		<p v-if="errors.title" class="error">{{ errors.title }}</p>
		<div v-bind:class="{ erroneous: errors.title }" class="form-row">
			<label for="id_title">{{ translate('Akad. Grad (optional)') }}</label>
			<input id="id_title" v-model="formData.title" type="text">
		</div>

		<p v-if="errors.first_name" class="error">{{ errors.first_name }}</p>
		<div v-bind:class="{ erroneous: errors.first_name }" class="form-row">
			<label for="id_first_name">{{ translate('Vorname') }}</label>
			<input id="id_first_name" v-model="formData.first_name" type="text">
		</div>

		<p v-if="errors.last_name" class="error">{{ errors.last_name }}</p>
		<div v-bind:class="{ erroneous: errors.last_name }" class="form-row">
			<label for="id_last_name">{{ translate('Nachname') }}</label>
			<input id="id_last_name" v-model="formData.last_name" type="text">
		</div>

		<p v-if="errors.address" class="error">{{ errors.address }}</p>
		<div v-bind:class="{ erroneous: errors.address }" class="form-row">
			<label for="id_address">{{ translate('Adresse') }}</label>
			<input id="id_address" v-model="formData.address" type="text">
		</div>

		<p v-if="errors.postcode" class="error">{{ errors.postcode }}</p>
		<p v-if="errors.city" class="error">{{ errors.city }}</p>
		<div v-bind:class="{ erroneous: errors.postcode }" class="form-row">
			<label for="id_postcode">{{ translate('PLZ und Ort') }}</label>
			<div class="postcode-city-wrap">
				<input id="id_postcode" v-model="formData.postcode" class="input-small" type="text">
				<input id="id_city" v-model="formData.city" class="input-medium" type="text">
			</div>
		</div>

		<p v-if="errors.state" class="error">{{ errors.state }}</p>
		<transition name="additionalFormField">
			<div v-if="stateList" v-bind:class="{ erroneous: errors.state }" class="form-row" v-bind:key="formData.country_id">
				<label for="id_state">{{ translate('Bundesland') }}</label>
				<select id="id_state" v-model="formData.state">
					<option disabled selected>{{ translate('Bitte auswählen') }}</option>
					<option v-for="state of stateList" v-bind:value="state" v-bind:key="state">{{ state }}</option>
				</select>
			</div>
		</transition>

		<p v-if="errors.country_id" class="error">{{ errors.country_id }}</p>
		<div v-bind:class="{ erroneous: errors.country_id }" class="form-row">
			<label for="id_country_id">{{ translate('Land') }}</label>
			<select id="id_country_id" v-model="formData.country_id" v-on:change="delete formData.state">
				<option disabled selected>{{ translate('Bitte auswählen') }}</option>
				<option v-for="country of getTranslations().country_list" v-bind:value="country.id" v-bind:key="country.id">{{ country.name }}</option>
			</select>
		</div>

		<p v-if="errors.phone_number" class="error">{{ errors.phone_number }}</p>
		<div v-bind:class="{ erroneous: errors.phone_number }" class="form-row">
			<label for="id_phone_number">{{ translate('Telefon (optional)') }}</label>
			<input id="id_phone_number" v-model="formData.phone_number" type="tel">
		</div>

		<p v-if="errors.newsletter" class="error">{{ errors.newsletter }}</p>
		<div v-bind:class="{ erroneous: errors.newsletter }" class="form-row option-row">
			<input id="id_newsletter" v-model="formData.newsletter" type="checkbox">
			<label for="id_newsletter">{{ translate('Newsletter abonnieren (optional)') }}</label>
		</div>

		<p v-if="errors.newsletter_jugend" class="error">{{ errors.newsletter_jugend }}</p>
		<div v-bind:class="{ erroneous: errors.newsletter_jugend }" class="form-row option-row">
			<input id="id_newsletter_jugend" v-model="formData.newsletter_jugend" type="checkbox">
			<label for="id_newsletter_jugend">{{ translate('Jugend-Newsletter abonnieren (optional)') }}</label>
		</div>

		<div style="opacity: 0; position: absolute; top: 0; left: 0; height: 0; width: 0; z-index: -1;">
			<label>
				{{ translate('Lassen Sie dieses Feld leer, um zu beweisen, dass Sie ein Mensch sind!') }}
				<input type="text" name="email2" value="" id="id_email2" v-model="formData.email2">
			</label>
		</div>

		<div class="form-row button-row">
			<button type="submit" class="button gradient small-only-expanded">{{ translate('Konto erstellen') }}</button>
		</div>
	</form>
</template>

<script>
    import formForShopApiMixin from '@/mixins/formForShopApi'
    export default {
        name: 'RegistrationForm',
        mixins: [formForShopApiMixin],
        data () {
            return {
                formUrl: 'user',
				formData: {
                    language: window.SHOP_APP.languageCode,
				}
            }
        },
        methods: {
            async validate (data) {
                if (data.username != data.username_repeat) {
                    this.errors['username_repeat'] = this.translate('Die E-Mail Adressen stimmen nicht überein')
                }
                if (data.password != data.password_repeat) {
                    this.errors['password_repeat'] = this.translate('Die Passwörter stimmen nicht überein')
                }
                const requiredFields = ['username', 'password', 'first_name', 'last_name', 'address', 'city', 'postcode', 'country_id']
				if (window.SHOP_APP.languageCode != 'ja') {
					requiredFields.push('salutation')
				}
                for (let field of requiredFields) {
                    if (data[field] == undefined || data[field].length < 1) {
                        this.errors[field] = this.translate('This field is required.')
                    }
                }
                if (Object.keys(this.errors).length == 0) {
                    data.recaptcha_token = await this.getRecaptchaToken()
                }
                return data
            },
            async getRecaptchaToken () {
                return new Promise((resolve) => {
                    // eslint-disable-next-line no-undef
                    grecaptcha.ready(async () => {
                        // eslint-disable-next-line no-undef
                        const token = await grecaptcha.execute(SHOP_APP.recaptchaKey);
                        resolve(token);
                    });
                });
            },
            async formSuccess (data) {
                // eslint-disable-next-line no-undef
                window.location.replace(SHOP_APP.urls.registration_completed)
                return data
			}
        },
        computed: {
            stateList () {
                for (let country of this.getTranslations().country_list) {
                    if (country.id === this.formData.country_id && Object.prototype.hasOwnProperty.call(country, 'states')) {
                        return country.states
                    }
                }
                return null
            }
        },
    }
</script>

<!--
<style scoped>
	.additionalFormField-enter-active{
		transition: opacity 1.5s;
	}
	.additionalFormField-leave-active {
		opacity: 0;
	}
	.additionalFormField-enter, .additionalFormField-leave-to {
		opacity: 0;
	}
</style>
-->
