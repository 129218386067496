<template>
	<login-required>
		<form v-on:submit.prevent="updatePassword" v-bind:class="{ error: error }" class="custom-form send-reset-password-mail-form small-form">
			<div class="form-row">
				<label for="id_old_password">{{ translate('Ihr aktuelles Passwort') }}</label>
				<input id="id_old_password" v-model="formData.old_password" type="password">
			</div>
			<p v-if="errors.new_password" class="error">{{ errors.new_password }}</p>
			<div v-bind:class="{ erroneous: errors.new_password }" class="form-row">
				<label for="id_password">{{ translate('Neues Passwort') }}</label>
				<input id="id_password" v-model="formData.new_password" type="password">
			</div>
			<p v-if="errors.password_repeat" class="error">{{ errors.password_repeat }}</p>
			<div v-bind:class="{ erroneous: errors.password_repeat }" class="form-row">
				<label for="id_password_repeat">{{ translate('Passwort wiederholen') }}</label>
				<input id="id_password_repeat" v-model="password_repeat" type="password">
			</div>
			<div class="form-row button-row">
				<button type="submit" class="button gradient small-only-expanded">{{ translate('Passwort ändern') }}</button>
			</div>
		</form>
	</login-required>
</template>

<script>
    import ShopApiForm from '@/mixins/formForShopApi'
	import LoginRequired from '@/components/LoginRequired'
    export default {
        name: 'ChangePasswordForm',
		components: { LoginRequired },
        mixins: [ShopApiForm],
        data () {
            return {
                formData: {
				},
                errors: {},
                formUrl: `user/${this.$store.getters.userId}/password`,
                password_repeat: '',
            }
        },
        methods: {
            async updatePassword () {
                const api = await this.$store.dispatch('api')
                await this.callAPI({ axios: api, method: 'patch' })
            },
            async validate (data) {
                if (data.new_password != this.password_repeat) {
                    this.errors['password_repeat'] = this.translate('Die Passwörter stimmen nicht überein')
                }
                return data
            },
            async formSuccess (data) {
                window.location.replace(window.SHOP_APP.urls.change_password_completed)
                return data
            }
        },
    }
</script>
