<template>
	<form v-on:submit.prevent="callAPI" v-bind:class="{ error: error }" class="custom-form small-form">

		<p v-if="errors.salutation" class="error">{{ errors.salutation }}</p>
		<div v-bind:class="{ erroneous: errors.salutation }" class="form-row">
			<label for="id_salutation">{{ translate('Anrede (optional)') }}</label>
			<select id="id_salutation" v-model="formData.salutation">
				<option disabled selected>{{ translate('Bitte auswählen') }}</option>
				<option value="Herr">Herr</option>
				<option value="Frau">Frau</option>
			</select>
		</div>

		<p v-if="errors.first_name" class="error">{{ errors.first_name }}</p>
		<div v-bind:class="{ erroneous: errors.first_name }" class="form-row">
			<label for="id_first_name">{{ translate('Vorname (optional)') }}</label>
			<input id="id_first_name" v-model="formData.first_name" type="text">
		</div>

		<p v-if="errors.last_name" class="error">{{ errors.last_name }}</p>
		<div v-bind:class="{ erroneous: errors.last_name }" class="form-row">
			<label for="id_last_name">{{ translate('Nachname (optional)') }}</label>
			<input id="id_last_name" v-model="formData.last_name" type="text">
		</div>

		<p v-if="errors.email" class="error">{{ errors.email }}</p>
		<div v-bind:class="{ erroneous: errors.email }" class="form-row">
			<label for="id_email">{{ translate('E-Mail') }}</label>
			<input id="id_email" v-model="formData.email" type="text">
		</div>

		<p v-if="errors.newsletter" class="error">{{ errors.newsletter }}</p>
		<div v-bind:class="{ erroneous: errors.newsletter }" class="form-row option-row">
			<input id="id_newsletter" v-model="newsletter" type="checkbox">
			<label for="id_newsletter">{{ translate('Wiener Philharmoniker Newsletter abonnieren') }}</label>
		</div>

		<p v-if="errors.jugend" class="error">{{ errors.jugend }}</p>
		<div v-bind:class="{ erroneous: errors.jugend }" class="form-row option-row">
			<input id="id_newsletter_jugend" v-model="newsletter_jugend" type="checkbox">
			<label for="id_newsletter_jugend">{{ translate('Jugend-Newsletter abonnieren') }}</label>
		</div>

		<div class="form-row button-row">
			<button type="submit" class="button gradient small-only-expanded">{{ translate('Newsletter abonnieren') }}</button>
		</div>
	</form>
</template>

<script>
    import FormForShopApiMixin from '@/mixins/formForShopApi'
    export default {
        name: 'NewsletterForm',
        mixins: [FormForShopApiMixin],
        data () {
            return {
                newsletter: false,
                newsletter_jugend: false,
                formData: {
                },
                formUrl: `newsletter`,
                formOptions: { method: 'post' },
            }
        },
        methods: {
            async formSuccess (data) {
                window.location.replace(window.SHOP_APP.urls.newsletter_optin_sent)
                return data
            },
            async formError (error) {
				const list_ids = JSON.parse(error.config.data).list_ids
				for (const k in this.errors) {
					if ( ! isNaN(k) ) {
						this.errors[list_ids[k]] = this.translate(this.errors[k])
					}
				}
                return error
            },
            async validate (data) {
				data.list_ids = []
                if (this.newsletter) {
                    data.list_ids.push('newsletter')
                }
                if (this.newsletter_jugend) {
                    data.list_ids.push('jugend')
                }
                if (data.list_ids.length == 0) {
                    this.errors['newsletter'] = this.translate('Bitte wählen Sie mindestens einen Newsletter')
                }
                return data
            },
        },
    }
</script>
