<template>
	<div class="main-section content content-full">
		<div class="grid-x grid-padding-x align-center">
			<div class="cell medium-8 large-8">
				<h2 class="text-center">{{ translate('Rechnungsadresse ändern') }}</h2>
			</div>
		</div>

		<div class="grid-x grid-padding-x align-center">
			<div class="cell medium-7 large-10 xlarge-9">

				<form v-on:submit.prevent="updateInvoiceAddress" class="custom-form invoice-address-form">

					<p v-if="errors.salutation" class="error">{{ errors.salutation }}</p>
					<div v-bind:class="{ erroneus: errors.salutation }" class="form-row">
						<label for="id_salutation">{{ translate('Anrede') }}</label>
						<select id="id_salutation" v-model="formData.salutation" ref="salute" v-bind:key="formData.salutation">
							<option disabled>{{ translate('Bitte auswählen') }}</option>
							<option v-for="salutation of getTranslations().salutation_list" v-bind:value="salutation" v-bind:key="salutation">{{ salutation }}</option>
						</select>
					</div>

					<p v-if="errors.title" class="error">{{ errors.title }}</p>
					<div v-bind:class="{ erroneous: errors.title }" class="form-row">
						<label for="id_title">{{ translate('Akad. Grad') }}</label>
						<input id="id_title" v-model="formData.title" type="text">
					</div>

					<p v-if="errors.first_name" class="error">{{ errors.first_name }}</p>
					<div v-bind:class="{ erroneus: errors.first_name }" class="form-row">
						<label for="id_first_name">{{ translate('Vorname') }}</label>
						<input id="id_first_name" v-model="formData.first_name" type="text">
					</div>

					<p v-if="errors.last_name" class="error">{{ errors.last_name }}</p>
					<div v-bind:class="{ erroneus: errors.last_name }" class="form-row">
						<label for="id_last_name">{{ translate('Nachname') }}</label>
						<input id="id_last_name" v-model="formData.last_name" type="text">
					</div>

					<p v-if="errors.address" class="error">{{ errors.address }}</p>
					<div v-bind:class="{ erroneus: errors.address }" class="form-row">
						<label for="id_address">{{ translate('Adresse') }}</label>
						<input id="id_address" v-model="formData.address" type="text">
					</div>

					<p v-if="errors.postcode" class="error">{{ errors.postcode }}</p>
					<p v-if="errors.city" class="error">{{ errors.city }}</p>
					<div v-bind:class="{ erroneus: errors.postcode }" class="form-row">
						<label for="id_postcode">{{ translate('PLZ und Ort') }}</label>
						<input id="id_postcode" v-model="formData.postcode" class="input-small" type="text">
						<input id="id_city" v-model="formData.city" class="input-medium" type="text">
					</div>

					<p v-if="errors.state" class="error">{{ errors.state }}</p>
					<transition name="additionalFormField">
						<div v-if="stateList" v-bind:class="{ erroneus: errors.state }" class="form-row" v-bind:key="formData.state">
							<label for="id_state">{{ translate('Bundesland') }}</label>
							<select id="id_state" v-model="formData.state" ref="state" v-bind:key="formData.state">
								<option disabled>{{ translate('Bitte auswählen') }}</option>
								<option v-for="state of stateList" v-bind:value="state" v-bind:key="state">{{ state }}</option>
							</select>
						</div>
					</transition>

					<p v-if="errors.country_id" class="error">{{ errors.country_id }}</p>
					<div v-bind:class="{ erroneus: errors.country_id }" class="form-row">
						<label for="id_country_id">{{ translate('Land') }}</label>
						<select id="id_country_id" v-model="formData.country_id" v-on:change="formData.state = null" ref="country" v-bind:key="formData.country_id">
							<option disabled>{{ translate('Bitte auswählen') }}</option>
							<option v-for="country of getTranslations().country_list" v-bind:value="country.id" v-bind:key="country.id">{{ country.name }}</option>
						</select>
					</div>

					<p v-if="errors.phone_number" class="error">{{ errors.phone_number }}</p>
					<div v-bind:class="{ erroneus: errors.phone_number }" class="form-row">
						<label for="id_phone_number">{{ translate('Telefon') }}</label>
						<input id="id_phone_number" v-model="formData.phone_number" type="tel">
					</div>

					<div class="form-row">
						<button v-if="saved" type="button" transition="savedTransition" class="button hollow secondary small-only-expanded">{{ translate('Gespeichert') }}</button>
						<button v-else type="submit" class="button gradient small-only-expanded">{{ translate('Speichern') }}</button>
					</div>

				</form>

			</div>
		</div>

	</div>
</template>

<script>
	import ShopApiForm from '@/mixins/formForShopApi'
    export default {
        name: 'EditInvoiceAddressForm',
		mixins: [ShopApiForm],
		props: {
            initial: {},
			successCallback: null,
			formUrl: null,
		},
        data () {
            return {
                formData: {},
                errors: {},
                saved: false,
            }
        },
        computed: {
            stateList () {
                for (let country of this.getTranslations().country_list) {
                    if (country.id === this.formData.country_id && Object.prototype.hasOwnProperty.call(country, 'states')) {
                        return country.states
                    }
                }
                return null
            }
        },
        methods: {
            async updateInvoiceAddress () {
                const api = await this.$store.dispatch('api')
				await this.callAPI({ axios: api, method: 'patch' })
            },
			initFormData () {
                this.formData = this.initial
			},
            async formSuccess (data) {
                await this.successCallback(data)
                this.saved = true
            },
        },
        async mounted () {
            this.initFormData()
        },
    }
</script>
