<template>
	<span v-if="$store.getters.expiredJTSession() == false && $store.state.cart.tickets.seats && $store.state.cart.tickets.seats.length" class="countdown-timer" v-bind:class="{ urgent: minutes < 3 }">{{ countdown }}</span>
</template>

<script>
	export default {
		name: 'TicketReservationCountdown',
		data () {
			return {
				countdown: null,
				minutes: null,
			}
		},
		methods: {
			setCountdown () {
				const secondsTotal = this.$store.state.cart.tickets.expires - Date.now() / 1000
				this.minutes = Math.floor(secondsTotal / 60)
				const seconds = Math.floor(secondsTotal % 60)
				this.countdown = `${this.minutes}:${seconds < 10 ? '0' : ''}${seconds}`
			},
		},
		mounted () {
			this.setCountdown()
			this.interval = window.setInterval(() => this.setCountdown(), 1000)
		},
		beforeDestroy () {
			window.clearInterval(this.interval)
		},
	}
</script>
