<template>
	<a :href="href" :class="classNames">
		<span v-if="itemCount > 0" class="badge">{{ itemCount }}</span>
		<ticket-reservation-countdown></ticket-reservation-countdown>
	</a>
</template>

<script>
	import translationMixin from '@/mixins/translation'
	import TicketReservationCountdown from '@/components/TicketReservationCountdown'
	export default {
		name: 'CartIcon',
		mixins: [translationMixin],
		components: {TicketReservationCountdown},
		props: {
			classNames: String,
			href: {
				type: String,
				default: 'javascript:;'
			},
		},
		computed: {
			itemCount () {
				const seats = this.$store.state.cart.tickets.seats || []
				return this.$store.state.cart.articles.reduce((accumulator, currentValue) => accumulator + currentValue.quantity, 0) + seats.length
			},
		},
	}
</script>
