<template>
    <li class="nav-li dropdown-container">
		<cart-icon classNames="nav-a open-dropdown icon cart-icon"/>
        <div v-if="articles.length + seats.length > 0" class="cart-article-box dropdown-content">
            <span class="cart-headline preline text-center">{{ translate('Meine Artikel') }}</span>
			<div v-if="seats.length > 0" class="information reserved">
				<ticket-reservation-countdown></ticket-reservation-countdown>
				<span>{{ translate('Karten reserviert') }}</span>
			</div>
            <div class="cart-articles">
				<div v-for="seat in seats" v-bind:key="seat.event_id + '-' + seat.id" class="article-row">
					<div class="article-name text-light subline">
						<span v-html="seat.event_name.replace(', ', ',<br>')"></span>,
						{{ seat.location }}<br>
						<span v-html="seat.seat_name.replace(', ', ',<br>')"></span>
					</div>
					<div class="article-image">
						<img v-bind:src="seat.event_img" />
					</div>
				</div>
                <div v-for="article in articles" v-bind:key="article.id" class="article-row">
                    <div class="article-name text-light subline">
						<span v-if="article.quantity > 1">{{ article.quantity }}x</span>
						{{ article.name }}
					</div>
                    <div class="article-image">
                        <img v-bind:src="article.img" />
                    </div>
                </div>
            </div>
            <div class="price-summary subline">
                <div class="price-row">
                    <span class="total-title text-light">{{ translate('Bestellwert') }} </span>
                    <span class="total-value">
                        <span class="net-sum primary-color">€ {{ netSum }}</span>
                        <span class="tax-info">{{ translate('Inkl. Ust.') }}</span>
                    </span>
                </div>
            </div>
            <div class="button-row">
                <a :href="urls.checkout" class="button gradient expanded">{{ translate('Zur Kasse') }}</a>
                <a :href="urls.cart" class="button hollow secondary expanded">{{ translate('Warenkorb anzeigen') }}</a>
            </div>
        </div>
		<div v-else class="cart-article-box dropdown-content">
			<span class="cart-headline preline text-center" v-html="translate('Ihr Warenkorb ist leer')"></span>
		</div>
    </li>
</template>

<script>
    import translationMixin from '@/mixins/translation'
	import TicketReservationCountdown from '@/components/TicketReservationCountdown'
	import CartIcon from '@/components/CartIcon'
    export default {
        name: 'CartWidget',
        mixins: [translationMixin],
		components: { TicketReservationCountdown, CartIcon },
        computed: {
            articles () {
                return this.$store.state.cart.articles
            },
            seats () {
                return this.$store.state.cart.tickets.seats || []
            },
            netSum () {
                return this.priceFormat(this.$store.getters.cartSum)
            },
        },
        mounted () {
            if (window.$) {
                window.$(".open-dropdown.cart-icon").dropdownOpen();
            }
        },
    }
</script>
