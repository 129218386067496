<template>
	<div class="main-section content content-full">
		<div class="grid-x grid-padding-x align-center">
			<div class="cell medium-8 large-8">
				<h2 class="text-center">{{ translate('Lieferadresse ändern') }}</h2>
			</div>
		</div>

		<div class="grid-x grid-padding-x align-center">
			<div class="cell medium-7 large-10 xlarge-9">

				<form v-on:submit.prevent="updateDeliveryAddress" class="custom-form delivery-address-form">

					<p v-if="errors.delivery_salutation" class="error">{{ errors.delivery_salutation }}</p>
					<div v-bind:class="{ erroneus: errors.delivery_salutation }" class="form-row">
						<label for="id_delivery_salutation">{{ translate('Anrede') }}</label>
						<select id="id_delivery_salutation" v-model="formData.delivery_salutation" ref="salute" v-bind:key="formData.delivery_salutation">
							<option disabled>{{ translate('Bitte auswählen') }}</option>
							<option v-for="salutation of getTranslations().salutation_list" v-bind:value="salutation" v-bind:key="salutation">{{ salutation }}</option>
						</select>
					</div>

					<p v-if="errors.delivery_first_name" class="error">{{ errors.delivery_first_name }}</p>
					<div v-bind:class="{ erroneus: errors.delivery_first_name }" class="form-row">
						<label for="id_delivery_first_name">{{ translate('Vorname') }}</label>
						<input id="id_delivery_first_name" v-model="formData.delivery_first_name" type="text">
					</div>

					<p v-if="errors.delivery_last_name" class="error">{{ errors.delivery_last_name }}</p>
					<div v-bind:class="{ erroneus: errors.delivery_last_name }" class="form-row">
						<label for="id_delivery_last_name">{{ translate('Nachname') }}</label>
						<input id="id_delivery_last_name" v-model="formData.delivery_last_name" type="text">
					</div>

					<p v-if="errors.delivery_address" class="error">{{ errors.delivery_address }}</p>
					<div v-bind:class="{ erroneus: errors.delivery_address }" class="form-row">
						<label for="id_delivery_address">{{ translate('Adresse') }}</label>
						<input id="id_delivery_address" v-model="formData.delivery_address" type="text">
					</div>

					<p v-if="errors.delivery_postcode" class="error">{{ errors.delivery_postcode }}</p>
					<p v-if="errors.delivery_city" class="error">{{ errors.delivery_city }}</p>
					<div v-bind:class="{ erroneus: errors.delivery_postcode }" class="form-row">
						<label for="id_delivery_postcode">{{ translate('PLZ und Ort') }}</label>
						<input id="id_delivery_postcode" v-model="formData.delivery_postcode" class="input-small" type="text">
						<input id="id_delivery_city" v-model="formData.delivery_city" class="input-medium" type="text">
					</div>

					<p v-if="errors.delivery_state" class="error">{{ errors.delivery_state }}</p>
					<transition name="additionalFormField">
						<div v-if="stateList" v-bind:class="{ erroneus: errors.delivery_state }" class="form-row" v-bind:key="formData.delivery_country_id">
							<label for="id_delivery_delivery_state">{{ translate('Bundesland') }}</label>
							<select id="id_delivery_delivery_state" v-model="formData.delivery_state" ref="state" v-bind:key="formData.delivery_state">
								<option disabled>{{ translate('Bitte auswählen') }}</option>
								<option v-for="delivery_state of stateList" v-bind:value="delivery_state" v-bind:key="delivery_state">{{ delivery_state }}</option>
							</select>
						</div>
					</transition>

					<p v-if="errors.delivery_country_id" class="error">{{ errors.delivery_country_id }}</p>
					<div v-bind:class="{ erroneus: errors.delivery_country_id }" class="form-row">
						<label for="id_delivery_delivery_country_id">{{ translate('Land') }}</label>
						<select id="id_delivery_delivery_country_id" v-model="formData.delivery_country_id" v-on:change="formData.delivery_state = null" ref="country" v-bind:key="formData.delivery_country_id">
							<option disabled>{{ translate('Bitte auswählen') }}</option>
							<option v-for="country of getTranslations().country_list" v-bind:value="country.id" v-bind:key="country.id">{{ country.name }}</option>
						</select>
					</div>

					<p v-if="errors.delivery_phone_number" class="error">{{ errors.delivery_phone_number }}</p>
					<div v-bind:class="{ erroneus: errors.delivery_phone_number }" class="form-row">
						<label for="id_delivery_phone_number">{{ translate('Telefon') }}</label>
						<input id="id_delivery_phone_number" v-model="formData.delivery_phone_number" type="tel">
					</div>

					<div class="form-row button-row">
						<button v-if="saved" type="button" transition="savedTransition" class="button hollow primary small-only-expanded saved">{{ translate('Gespeichert') }}</button>
						<button v-else type="submit" class="button gradient small-only-expanded">{{ translate('Speichern') }}</button>
					</div>

				</form>

			</div>
		</div>

	</div>
</template>

<script>
	import ShopApiForm from '@/mixins/formForShopApi'
    export default {
        name: 'EditDeliveryAddressForm',
		mixins: [ShopApiForm],
		props: {
            initial: {},
			successCallback: null,
			formUrl: null,
		},
        data () {
            return {
                formData: {},
                errors: {},
                saved: false,
            }
        },
        computed: {
            stateList () {
                for (let country of this.getTranslations().country_list) {
                    if (country.id === this.formData.delivery_country_id && Object.prototype.hasOwnProperty.call(country, 'states')) {
                        return country.states
                    }
                }
                return null
            }
        },
        methods: {
            async updateDeliveryAddress () {
                const api = await this.$store.dispatch('api')
				await this.callAPI({ axios: api, method: 'patch' })
            },
			initFormData () {
                this.formData = this.initial
			},
            async formSuccess (data) {
                await this.successCallback(data)
                this.saved = true
            },
        },
        async mounted () {
            this.initFormData()
        },
    }
</script>
