<template>
    <div class="product-widget">
		<div class="dropdown-list article">
			<div class="dropdown-container">
				<a href="javascript:;" class="dropdown-a open-dropdown" ref="dropDownOpener">
					<span class="num">{{ quantity }}</span>
					<span class="arrow"></span>
				</a>
				<div class="list-wrapper">
					<ul class="dropdown-list-ul">
						<li v-for="q in parseInt(maxQuantity, 10)" v-bind:value="q" v-bind:key="q">
							<a v-on:click.prevent="setQuantity(q)" href="#">{{ q }}</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
        <p class="subline primary-color">{{ priceFormat(priceFloat) }} €</p>
		<br>
        <button v-on:click="addToCart" class="button gradient mt-2">{{ translate(buttonText) }}</button>
        <div class="mobile-notification" :class="{show: cartAnimationTimeout != null}">
            <span class="shop-icon"></span> {{ translate('Artikel wurde in Ihren Warenkorb gelegt') }}
        </div>
    </div>
</template>

<script>
    import translationMixin from '@/mixins/translation'
    export default {
        name: 'ProductWidget',
        mixins: [translationMixin],
        props: {
            id: {
                type: String,
                required: true,
                validator: (prop) => !isNaN(prop),
            },
            name: String,
            price: {
                type: String,
                required: true,
                validator: (prop) => !isNaN(prop),
            },
            img: String,
            category: String,
            buttonText: {
                type: String,
                default: 'In den Warenkorb',
            },
            maxQuantity: {
                type: String,
                default: '10',
                validator: (prop) => !isNaN(prop),
            },
            url: String,
        },
        data () {
            return {
                quantity: this.quantity ? parseInt(this.quantity, 10) : 1,
				cartAnimationTimeout: null,
            }
        },
        methods: {
            addToCart () {
                this.$store.commit('addArticle', {
                    id: parseInt(this.id, 10),
                    price: this.priceFloat,
                    quantity: this.quantity,
                    name: this.name,
                    category: this.category,
                    img: this.img,
                    url: this.url,
                    maxQuantity: parseInt(this.maxQuantity, 10),
                })
                this.startCartAnimation()
            },
            setQuantity (quantity) {
                this.quantity = quantity
                this.$refs.dropDownOpener.click()
            },
            startCartAnimation () {
                this.stopCartAnimation()
                for (const el of document.querySelectorAll('.cart-icon')) {
                    el.parentNode.classList.add('open', 'cart-anim')
                }
                this.cartAnimationTimeout = window.setTimeout(() => this.stopCartAnimation(), 3000)
            },
            stopCartAnimation () {
                if (this.cartAnimationTimeout) {
                    for (const el of document.querySelectorAll('.cart-icon')) {
                        el.parentNode.classList.remove('open', 'cart-anim')
                    }
                    window.clearTimeout(this.cartAnimationTimeout)
                    this.cartAnimationTimeout = null
                }
            },
        },
        computed: {
            priceFloat () {
                return parseFloat(this.price, 10)
            }
        },
        mounted () {
            if (window.$) {
                window.$(this.$refs.dropDownOpener).dropdownOpen();
            }
        },
        beforeDestroy () {
            this.stopCartAnimation()
        },
    }
</script>
