<template>
	<span>
		{{ stateFormat }}
	</span>
</template>

<script>
	import translationMixin from '@/mixins/translation'
	export default {
		name: 'StateFormat',
		mixins: [translationMixin],
		props: {
			country_id: Number,
			postcode: String,
			state: String,
			city: String,
		},
		data () {
			const parts = []
			let stateFormat = 'postcode,city,state'
			for (let country of this.getTranslations().country_list) {
				if (country.id === this.country_id && country.state_format) {
					stateFormat = country.state_format
				}
			}
			for (let field of stateFormat.split(',')) {
				parts.push(this.$props[field])
			}
			return {
				stateFormat: parts.join(', '),
			}
		},
	}
</script>
