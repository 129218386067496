import Vue from 'vue'
import store from './store'

import RegistrationForm from '@/components/RegistrationForm.vue'
import OptinForm from '@/components/OptinForm.vue'
import LoginForm from '@/components/LoginForm.vue'
import ProductWidget from '@/components/ProductWidget.vue'
import CartWidget from '@/components/CartWidget.vue'
import CartIcon from '@/components/CartIcon.vue'
import UserWidget from '@/components/UserWidget.vue'
import CartEditor from '@/components/CartEditor.vue'
import CheckoutForm from '@/components/CheckoutForm.vue'
import BuyTicketsLink from '@/components/BuyTicketsLink.vue'
import SendResetPasswordMailForm from '@/components/SendResetPasswordMailForm.vue'
import ResetPasswordForm from '@/components/ResetPasswordForm.vue'
import SelfCareIndex from '@/components/SelfCareIndex.vue'
import OrderList from '@/components/OrderList.vue'
import OrderDetail from '@/components/OrderDetail.vue'
import OrderSuccess from '@/components/OrderSuccess.vue'
import ChangePasswordForm from '@/components/ChangePasswordForm.vue'
import ReserveBestSeatsForm from '@/components/ReserveBestSeatsForm.vue'
import NewsletterForm from '@/components/NewsletterForm.vue'
import NewsletterButton from '@/components/NewsletterButton.vue'
import Interval from '@/components/Interval.vue'

Vue.config.productionTip = false

new Vue({
    store,
    render: h => h(Interval, {
        props: {delay: 8888},
        on: { tick: async function () { await store.dispatch('testToken') }, }
    })}).$mount()

new Vue({
    store,
    render: h => h(Interval, {
        props: {delay: 2000, wait: false},
        on: { tick: async function () { await store.dispatch('testJTSession') }, }
    })}).$mount()


for (let i of [
    ['.product-widget', ProductWidget],
    ['.buy-tickets-link', BuyTicketsLink],
    ['#cart-widget', CartWidget],
    ['#user-widget', UserWidget],
    ['#cart-editor', CartEditor],
	['#mobile-cart-icon', CartIcon],
    ['#registration-form', RegistrationForm],
    ['#optin-form', OptinForm],
    ['#login-form', LoginForm],
    ['#checkout-form', CheckoutForm],
    ['#send-reset-password-mail-form', SendResetPasswordMailForm],
    ['#reset-password-form', ResetPasswordForm],
    ['#self-care', SelfCareIndex],
    ['#order-list', OrderList],
    ['#order-detail', OrderDetail],
    ['#order-success', OrderSuccess],
    ['#change-password-form', ChangePasswordForm],
    ['#reserve-best-seats-form', ReserveBestSeatsForm],
    ['#newsletter-form', NewsletterForm],
    ['.newsletter-button', NewsletterButton],
]) {
    for (let el of document.querySelectorAll(i[0])) {
        const propsData = el.tagName == 'SCRIPT' ? JSON.parse(el.textContent) : el.dataset
        new Vue({
            store,
            render: h => h(i[1], { props: { ...propsData } })
        }).$mount(el);
    }

}


//TODO
/*
import EditDeliveryAddressForm from '@/components/EditDeliveryAddressForm.vue'
new Vue({
    store,
    render: h => h(EditDeliveryAddressForm, {props: {initial: {delivery_country_id:10, delivery_state:'NRW', delivery_first_name: 'Clara', delivery_last_name: null} }})
}).$mount(document.querySelectorAll('#delivery-addr')[0]);
*/
