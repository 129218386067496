<template>
	<form v-on:submit.prevent="callAPI" v-bind:class="{ error: error }" class="custom-form send-reset-password-mail-form small-form">
		<div class="form-row">
			<label for="id_username">{{ translate('Ihre E-Mail Adresse') }}</label>
			<input id="id_username" v-model="formData.username" type="email">
		</div>
		<div class="form-row button-row">
			<p v-if="error" class="error">{{ translate('Die Anmeldung ist fehlgeschlagen') }}</p>
			<button type="submit" class="button gradient small-only-expanded">{{ translate('Passwort anfordern') }}</button>
		</div>
	</form>
</template>

<script>
	import FormForShopApiMixin from '@/mixins/formForShopApi'
	export default {
		name: 'SendResetPasswordMailForm',
		mixins: [FormForShopApiMixin],
		data () {
			return {
				formUrl: `send-reset-password-mail`,
				error: false
			}
		},
		methods: {
			async formSuccess (data) {
				window.location.replace(window.SHOP_APP.urls.send_reset_password_mail_completed)
				return data
			},
			async formError () {
				this.error = true
			},
		},
	}
</script>
