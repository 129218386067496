<template>
	<login-required>
		<div class="grid-x grid-padding-x align-center">
			<div class="cell medium-10 xlarge-8">
				<h1 class="text-center">{{ translate('Mein Bereich') }}</h1>
			</div>

			<div class="cell medium-10 xlarge-8 grid-x grid-margin-x align-center">

				<div class="cell medium-4 address-block">
					<p class="subhead">{{ translate('Rechnungsadresse') }}</p>
					<p>
						{{ userInfo.salutation }}
						{{ userInfo.title }}
						{{ userInfo.first_name }}
						{{ userInfo.last_name }}
						<br>
						{{ userInfo.address }}
						<br>
						<template v-if="userInfo.state">
							<state-format :country_id="userInfo.country_id" :postcode="userInfo.postcode" :state="userInfo.state" :city="userInfo.city"/>
						</template>
						<template v-else>
							{{ userInfo.postcode }} {{ userInfo.city }}
						</template>
						<br>
						{{ countryName(userInfo.country_id) }}
					</p>
					<p>
						<a v-on:click.prevent="openInvoiceModal" class="link">{{ translate('Rechnungsadresse ändern') }}</a>
					</p>
				</div>
				<div class="cell medium-4 address-block">
					<p class="subhead">{{ translate('Lieferadresse') }}</p>
					<p v-if="userInfo.delivery_country_id">
						{{ userInfo.delivery_salutation }}
						{{ userInfo.delivery_title }}
						{{ userInfo.delivery_first_name }}
						{{ userInfo.delivery_last_name }}
						<br>
						{{ userInfo.delivery_address }}
						<br>
						<template v-if="userInfo.delivery_state">
							<state-format :country_id="userInfo.delivery_country_id" :postcode="userInfo.delivery_postcode" :state="userInfo.delivery_state" :city="userInfo.delivery_city"/>
						</template>
						<template v-else>
							{{ userInfo.delivery_postcode }} {{ userInfo.delivery_city }}
						</template>
						<br>
						{{ countryName(userInfo.delivery_country_id) }}
					</p>
					<p>
						<a v-on:click.prevent="openDeliveryModal" class="link">{{ translate('Lieferadresse ändern') }}</a>
					</p>
				</div>
				<div class="cell medium-4 address-block">
					<p class="subhead">{{ translate('E-Mail') }}</p>
					<p>
						{{ userInfo.username }}
					</p>
					<p v-html="translate('Wenn Sie Ihre E-Mail-Adresse ändern möchten, schicken Sie uns bitte eine E-Mail.')">
					</p>
				</div>
			</div>
			<div class="cell medium-10 xlarge-8 grid-x grid-margin-x align-center">
				<div class="cell medium-4 address-block">
					<p class="subhead">{{ translate('Passwort') }}</p>
					<p>
						<a :href="urls.change_password" class="link">{{ translate('Passwort ändern') }}</a>
					</p>
				</div>
				<div class="cell medium-8 address-block">
					<p class="subhead">{{ translate('Newsletter') }}</p>
					<p>
						{{ translate('Hier können Sie Ihre Newsletter verwalten.') }}
					</p>
					<p>
						<a :href="urls.newsletter_user_form" class="link">{{ translate('Ihre Newsletter Einstellungen ändern') }}</a>
					</p>
				</div>
			</div>
			<div class="cell medium-10 xlarge-8 grid-x grid-margin-x align-center">
				<a v-on:click.prevent="logout" href="#" class="button hollow secondary icon-logout">{{ translate('Abmelden') }}</a>
			</div>
		</div>

		<div class="grid-x grid-padding-x align-center">
			<div class="cell medium-10 xlarge-8">
				<hr>
			</div>
		</div>

		<div class="grid-x grid-padding-x align-center">
			<div class="cell medium-10 xlarge-8">
				<h2 class="text-center">{{ translate('Meine Bestellungen') }}</h2>
			</div>

			<div class="cell medium-8 large-10 xlarge-8">
				<order-list
						v-if="counter > 0"
						:limit="2"
						:key="'orderList'+counter"
				></order-list>
			</div>
		</div>

		<div id="delivery-address-form" style="display:none">
			<edit-delivery-address-form
					:initial="deliveryFormData"
					:formUrl="`user/${$store.getters.userId}`"
					:successCallback="userSuccessCallback"
					:key="'deliveryAddressForm'+counter"
					ref="deliveryAddressForm"
			></edit-delivery-address-form>
		</div>
		<div id="invoice-address-form" style="display:none">
			<edit-invoice-address-form
					:initial="invoiceFormData"
					:formUrl="`user/${$store.getters.userId}`"
					:successCallback="userSuccessCallback"
					:key="'invoiceAddressForm'+counter"
					ref="invoiceAddressForm"
			></edit-invoice-address-form>
		</div>

	</login-required>
</template>

<script>
	import _ from 'lodash'
	import EditDeliveryAddressForm from '@/components/EditDeliveryAddressForm.vue'
	import EditInvoiceAddressForm from '@/components/EditInvoiceAddressForm.vue'
	import OrderList from '@/components/OrderList.vue'
	import translationMixin from '@/mixins/translation'
	import LoginRequired from '@/components/LoginRequired'
	import StateFormat from '@/components/StateFormat'
	export default {
		name: 'SelfCareIndex',
		components: { EditDeliveryAddressForm, EditInvoiceAddressForm, OrderList, LoginRequired, StateFormat },
		mixins: [translationMixin],
		data () {
			return {
				formData: {
					use_delivery_address: false,
					payment_method: null,
				},
				error: false,
				loaded: false,
				userInfo: {},
				counter: 0,
			}
		},
		computed: {
			deliveryFormData () {
				return _.pickBy(this.userInfo, function (v, k) { return _.startsWith(k, 'delivery_')})
			},
			invoiceFormData () {
				return _.pickBy(this.userInfo, function (v, k) { return ! _.startsWith(k, 'delivery_')})
			},
		},
		methods: {
			async getUser () {
				this.loaded = false
				const api = await this.$store.dispatch('api')
				const response = await api.get(`${window.SHOP_APP.baseURL}/user/${this.$store.getters.userId}`)
				this.userInfo = response.data
				this.loaded = true
				this.counter++
			},
			countryName (country_id) {
				for (let country of this.getTranslations().country_list) {
					if (country.id === country_id) {
						return country.name
					}
				}
			},
			openDeliveryModal () {
				// eslint-disable-next-line no-undef
				if (window.$) {
					// eslint-disable-next-line no-undef
					$.fancybox.open({
						src: "#delivery-address-form",
						baseClass: "content-overlay",
					})
				}
			},
			openInvoiceModal () {
				// eslint-disable-next-line no-undef
				if (window.$) {
					// eslint-disable-next-line no-undef
					$.fancybox.open({
						src: "#invoice-address-form",
						baseClass: "content-overlay",
					})
				}
			},
			async userSuccessCallback (data) {
				this.userInfo = data
				this.loaded = true
				const compo = this
				if (window.$) {
					setTimeout( function () {
						// eslint-disable-next-line no-undef
						$.fancybox.close()
						compo.counter++
					}, 2088)
				}
			},
			async logout () {
				await this.$store.dispatch('logout')
                window.location.replace(window.SHOP_APP.urls.login)
			},
		},
		async created () {
			if (this.$store.getters.isLoggedIn()) {
				await this.getUser()
			}
		},
	}
</script>
