<template>
    <a v-on:click.prevent="redirect" :href="url" :class="classes">{{ label }}</a>
</template>

<script>
    export default {
        name: 'BuyTicketsLink',
        props: {
            url: null,
            label: null,
			classes: {
				type: String,
				default: 'button',
			},
        },
        methods: {
            redirect () {
                if (this.$store.getters.isLoggedIn()) {
                    // eslint-disable-next-line no-undef
                    window.location.assign(this.url)
                } else {
                    this.$store.dispatch('setNextURL', this.url)
                    // eslint-disable-next-line no-undef
                    if (window.$) {
                        // eslint-disable-next-line no-undef
                        $.fancybox.open($('#login-needed-for-tickets'))
                    }
                }
            },
        },
    }
</script>
