<template>
	<login-required>
		<div v-if="isSubscribed == true" class="text-center">
			<button class="button disabled" disabled>{{ translate('Sie abonnieren den Newsletter') }}</button><br>
			<a v-on:click.prevent="unsubscribe" href="#">{{ translate('Newsletter abmelden') }}</a>
		</div>
		<div v-if="isSubscribed === false" class="text-center">
			<button v-on:click.prevent="subscribe" class="button">{{ translate('Newsletter anmelden') }}</button>
		</div>
	</login-required>
</template>

<script>
	import TranslationMixin from '@/mixins/translation'
	import LoginRequired from '@/components/LoginRequired'
	export default {
		name: 'NewsletterForm',
		mixins: [TranslationMixin],
		components: { LoginRequired },
		props: {
			newsletter: String,
		},
		data () {
			return {
				isSubscribed: null,
			}
		},
		methods: {
			async subscribe () {
				this.isSubscribed = null
				try {
					await this.api.post(this.apiUrl)
					this.isSubscribed = true
				} catch (error) {
					if (error.response.status != 404) {
						this.showAPIError('Bitte wenden Sie sich an den Support.')
					}
				}
			},
			async unsubscribe () {
				this.isSubscribed = null
				try {
					await this.api.delete(this.apiUrl)
					this.isSubscribed = false
				} catch (error) {
					if (error.response.status != 404) {
						this.showAPIError('Bitte wenden Sie sich an den Support.')
					}
				}
			},
		},
		async created () {
			this.api = await this.$store.dispatch('api')
			this.apiUrl = `${window.SHOP_APP.baseURL}/newsletter/user/${this.newsletter}`
			try {
				await this.api.get(this.apiUrl)
				this.isSubscribed = true
			} catch (error) {
				if (error.response.status == 404) {
					this.isSubscribed = false
				} else {
					this.showAPIError('Bitte wenden Sie sich an den Support.')
				}
			}
		},
	}
</script>
