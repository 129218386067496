<template>
	<login-required>
		<table class="order-list">
			<tr>
				<th class="subhead">{{ translate('Bestell-Nr.') }}</th>
				<th class="subhead">{{ translate('Betrag') }}</th>
				<th class="subhead">{{ translate('Datum') }}</th>
				<th class="subhead">{{ translate('Status') }}</th>
				<th class="subhead">{{ translate('Rechnung') }}</th>
				<th class="subhead">{{ translate('Print@Home') }}</th>
			</tr>
			<tr v-for="order in orders" :key="order.id">
				<td class="subline" :data-header="translate('Bestell-Nr.')">
					<a :href="urls.order_detail + '#' + order.id">{{ order.id }}</a>
				</td>
				<td class="subline" :data-header="translate('Betrag')">€ {{ priceFormat(order.price) }}</td>
				<td class="subline" :data-header="translate('Datum')">{{ dateFormat(order.date) }}</td>
				<td class="subline" :data-header="translate('Status')">{{ translate(order.status) }}</td>
				<td v-if="order.tracking_ids.length > 0" class="subline" :data-header="translate('Sendung-Nr.')">
					<div>
						<template v-for="(tracking_id, index) in order.tracking_ids">
							<a :href="'https://www.post.at/sv/sendungssuche?snr=' + tracking_id" :key="tracking_id">{{ tracking_id }}</a>
							<br v-if="index != order.tracking_ids.length - 1" :key="tracking_id">
						</template>
					</div>
				</td>				
				<td class="subline" :data-header="translate('Rechnung')">
					<a v-if="order.invoice" :href="order.invoice" download>{{ translate('Rechnung herunterladen (PDF)') }}</a>
				</td>
				<td v-if="order.documents" class="subline" :data-header="translate('Print@Home')">
					<ul style="margin-left:0">
						<li v-for="doc in order.documents" :key="doc.name">
							<a :href="doc.url" :key="doc.name">{{ translate('Tickets herunterladen (PDF)') }}</a>
						</li>
					</ul>
				</td>
			</tr>
		</table>
	</login-required>
</template>

<script>
    import translationMixin from '@/mixins/translation'
	import LoginRequired from '@/components/LoginRequired'
    export default {
        name: 'OrderList',
		mixins: [translationMixin],
		components: { LoginRequired },
        props: {
            limit: null,
        },
        data () {
            return {
                orders: [],
            }
        },
        computed: {
        },
        methods: {
            async getOrders () {
                const api = await this.$store.dispatch('api')
                const response = await api.get(`${window.SHOP_APP.baseURL}/order`, this.limit ? { params: { limit: this.limit }} : {})
                this.orders = response.data.orders
            },
        },
        async mounted () {
            await this.getOrders()
        },
    }
</script>
