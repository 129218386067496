import moment from 'moment'
import _ from 'lodash'

export default {
    methods: {
        getTranslations () {
            return window.SHOP_APP.translations
        },
        translate (text, replacements) {
            const translations = this.getTranslations()
            if (Object.prototype.hasOwnProperty.call(translations, text)) {
                let translated = translations[text]
                if (replacements instanceof Object) {
                    for (let [key, value] of Object.entries(replacements)) {
                        translated = translated.replace('{' + key + '}', value)
                    }
                }
                return translated
            }
            return text
        },
        priceFormat (price) {
            if ( ! isNaN(price)) {
                return window.SHOP_APP.languageCode == 'de' ? price.toFixed(2).replace('.', ',') : price.toFixed(2)
            }
            return price
        },
        dateFormat (date, options = {}) {
            _.assignIn(options, { format: 'DATE' })
            const formats = {
                en: {
                    DATE: 'D/M/YYYY',
                },
                de: {
                    DATE: 'D.M.YYYY',
                },
                ja: {
                    DATE: 'YYYY年M月D',
                },
            }
            return moment(date).format(formats[window.SHOP_APP.languageCode][options.format])
        },
        showAPIError (message, replacements) {
            if (window.$) {
                window.$.fancybox.open(`<div class="api-error-message">\
                <h3>${this.translate('Es ist ein Fehler aufgetreten.')}</h3>\
                <p>${this.translate(message, replacements)}</p></div>`);
            }
            return true
        }
    },
    computed: {
        urls () {
            return window.SHOP_APP.urls
        }
    },
}